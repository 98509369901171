import React, { useState, useEffect, useRef } from 'react';
import Banner from './Banner';
import Input from './InputN';
import ModalAggre from './ModalAggre';

export default function App(props) {
    const recaptchaRef = useRef(null); // Используем useRef на верхнем уровне
    const [recaptchaValue, setRecaptchaValue] = useState(null); // Добавьте состояние для капчи
    const [isSubmitting, setIsSubmitting] = useState(false); // Добавьте состояние для отправки формы

    useEffect(() => {
        document.body.classList.add('reg');
        getRegistration();
        props.setErrTextApi('');
    }, []);

    const ErrMas = [];
    const getRegistration = () => {
        fetch(props.API_URL + '/registration/fields', {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response && response.status === true) {
                    props.setRoomesN([...Object.values(response.data.fields)]);
                    props.setRoomesN((roomesN) =>
                        roomesN?.map((list, index) =>
                            index === index
                                ? {
                                    ...list,
                                    ...(list['Err'] = false),
                                    ...(list['err'] = false),
                                    ...(list['value'] = ''),
                                    ...(list['errorText'] = ''),
                                }
                                : list,
                        ),
                    );
                    props.setRoomesN((room) =>
                        room?.map((list, index) =>
                            index === index ? { ...list, value: list.fields[0].value } : list,
                        ),
                    );
                }
            });
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value); // Устанавливаем значение капчи в состояние
    };

    useEffect(() => {
        if (isSubmitting && recaptchaValue)  {
            props.submit('registr', null, null, recaptchaValue); // Передаем значение капчи при отправке формы
            setIsSubmitting(false); // Сбрасываем флаг отправки после вызова
        }
    }, [recaptchaValue]); // Этот useEffect сработает, когда recaptchaValue или isSubmitting изменятся



    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    }

// Ваш исходный handleSubmit
    const handleSubmit = () => {
        recaptchaRef.current.execute(); // Выполнение капчи
        setIsSubmitting(true); // Устанавливаем флаг отправки
    };

// Оборачиваем handleSubmit в debounce с задержкой 500 мс
    const debouncedHandleSubmit = debounce(handleSubmit, 500);


    return (
        <div className="content__max">
            <div className="content__page">
                {props.errTextApi !== '' && (
                    <div
                        className="form__item form__err"
                        dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
                )}
                <div className="form text-center d-flex d-aling-center d-f-column">
                    <div className="form-title ">Регистрация</div>
                    <Input
                        rooms={props.roomesN}
                        setRooms={props.setRoomes}
                        setValidation={props.setValidation}
                        togglePass={props.togglePass}
                        handleroom={props.handleroom}
                        recaptchaRef={recaptchaRef} // Передаем ссылку на капчу в Input компонент
                        handleRecaptchaChange={handleRecaptchaChange} // Передаем обработчик изменений капчи
                    />
                    <button
                        onClick={debouncedHandleSubmit} // Вызываем handleSubmit при нажатии на кнопку
                        className={'btn' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
                        Создать учетную запись
                    </button>
                </div>
            </div>
            <div className="modals__list">
                <ModalAggre
                    modalShow={props.modalShow}
                    modalClose={props.modalClose}
                    modalData={props.modals[6]}
                    setErrTextApi={props.setErrTextApi}
                    key={props.modals[6].id}
                    errTextApi={props.errTextApi}
                />
            </div>
        </div>
    );
}
